<template>
  <v-theme-provider dark>
    <base-section
      id="social"
      class="accent text-center"
      space="56"
    >
      <base-icon class="mb-8">
        mdi-twitter
      </base-icon>

      <base-section-heading
        color="transparent"
        title="Social Media"
      >
        Please click the link below to visit our Facebook page.
      </base-section-heading>

      <a
        class="whitetext"
        href="https://vuetifyjs.com"
        style="text-decoration: none;"
        target="_blank"
      >
        https://vuetifyjs.com
      </a>

      <div class="py-4" />
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionNews',

    provide: {
      heading: { align: 'center' },
    },
  }
</script>

<style type="css" scoped>
  #social {
  background-color: darkslateblue !important;
}
  .whitetext{
    background-color: darkslateblue !important;
    color: white !important;
}
</style>
